import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Information = makeShortcode("Information");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "platforms"
    }}>{`Platforms`}</h1>
    <h2 {...{
      "id": "react"
    }}>{`React`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install our `}<a parentName="p" {...{
            "href": "https://www.npmjs.com/package/@livesession/sdk"
          }}>{`SDK`}</a>{` into your project`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Import LiveSession SDK`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import ls from "@livesession/sdk";
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`We recommend 2 ways to integrate SDK with your app:`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Init script in file where you're rendering a whole application:`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import ls from "@livesession/sdk";

ls.init("YOUR-TRACK-ID");
ls.newPageView();
ReactDOM.render(<App />, document.getElementById("root"));
`}</code></pre>
    <ul>
      <li parentName="ul">{`You can also use lifecycle method `}<inlineCode parentName="li">{`componentDidMount`}</inlineCode>{` in your main Layout component. That means you'll be able to enable script on every page.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import ls from "@livesession/sdk";

class Layout extends Component {
   componentDidMount(){
       ls.init("YOUR_TRACK_ID");
       ls.newPageView();
   }
   render() {
       return (
           // your layout
       );
   }
}

export default Layout;
`}</code></pre>
    <p>{`If you have React 16.8 or higher - try with `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` hook`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import ls from "@livesession/sdk";

const Layout = () => {
    useEffect(() => {
        ls.init("YOUR_TRACK_ID");
        ls.newPageView();
    },[])
    return (
        // your layout
    )
}

export default Layout;
`}</code></pre>
    <h2 {...{
      "id": "angular"
    }}>{`Angular`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Import SDK into your main app component`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Import `}<inlineCode parentName="p">{`OnInit`}</inlineCode>{` from `}<inlineCode parentName="p">{`@angular/core`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Implement `}<inlineCode parentName="p">{`OnInit`}</inlineCode>{` and call LiveSession init method in `}<inlineCode parentName="p">{`ngOnInit`}</inlineCode>{` function`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// app.component.ts
import ls from '@livesession/sdk'

export class AppComponent implemets OnInit {
    ngOnInit(){
        ls.init("YOUR_TRACK_ID");
        ls.newPageView();
    }
}
`}</code></pre>
    <h2 {...{
      "id": "gatsby"
    }}>{`Gatsby`}</h2>
    <Information color="info" mdxType="Information">LiveSession provides a gatsby plugin to integrate script</Information>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install `}<a parentName="p" {...{
            "href": "https://www.gatsbyjs.org/packages/@livesession/gatsby-plugin-livesession/"
          }}>{`gatsby-plugin-livesession`}</a></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go to `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Configure a plugin like in following example:`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`plugins: [
  {
    resolve: \`@livesession/gatsby-plugin-livesession\`,
    options: {
      trackID: YOUR_LIVESESSION_TRACKID, // Required, string
      keystrokes: true || false, // Optional, default to false
      rootHostname: ".example.com", // Optional
    },
  },
];
`}</code></pre>
    <p>{`Read more about an options you can provide `}<a parentName="p" {...{
        "href": "/javascript-api/configuration/"
      }}>{`here`}</a></p>
    <Information color="warning" mdxType="Information">Plugin adds code to your site only in production mode.</Information>
    <h2 {...{
      "id": "capacitor"
    }}>{`Capacitor`}</h2>
    <p>{`In general LiveSession support that technology, but you must be sure that resources on your app are available publicly on the Internet.`}</p>
    <p>{`If you use local server for some reasons you'll need to pass `}<inlineCode parentName="p">{`baseURL`}</inlineCode>{` option like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("newPageView", {
  baseURL: "https://your-site.com"
});
`}</code></pre>
    <Information color="info" mdxType="Information">
    For example if you set up your <a href="https://capacitorjs.com/docs/config#schema">local
    server</a> with <b>hostname:'frontend'</b>, <b>androidScheme:'capacitor-app'</b>
    {' '} and href to css in your app is <i>/static/style.css</i> the following style resource must be available
    at <i>https://your-site.com/static/style.css</i> assuming you set{' '}
    <b>baseURL</b> to <i>https://you-site.com</i>
    </Information>
    <h2 {...{
      "id": "electron"
    }}>{`Electron`}</h2>
    <p>{`Electron doesn't natively support cookies, so LiveSession JavaScript web tracking code won't work because internally we use client-side cookies.
We're currently working on a of the electron-cookies package to support tracking.`}</p>
    <Information color="info" mdxType="Information">In the future we'll switch to another browser storage instead of cookies and Electron should works out of the box.</Information>
    <p>{`So, to use LiveSession in Electron apps you should:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install `}<a parentName="p" {...{
            "href": "https://www.npmjs.com/package/@livesession/electron-cookies"
          }}>{`electron-cookies`}</a>{` package`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Configure a like in following example:`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import ElectronCookies from '@livesession/electron-cookies';

// enable
ElectronCookies.enable({
  origin: 'https://example.com'
}); // or ElectronCookies.enable() for default

// disable
ElectronCookies.disable();
`}</code></pre>
    <p>{`The browser cookies needs to know the current URL, but in Electron files are usually served from local filesystem which is not compatibile with cookies.
Treat `}<inlineCode parentName="p">{`origin`}</inlineCode>{` as a special key for cookies store within all data is saved.`}</p>
    <p>{`Read more about a package `}<a parentName="p" {...{
        "href": "https://github.com/livesession/electron-cookies"
      }}>{`here`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      